import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import fabriqoLogo from "../images/fabriqo-logo.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`fashion`, `tech`, `open-source`]}
        title="Home"
      />

      <section className="text-center">
        <img
          alt="Fabriqo Logo"
          className="block w-1/2 mx-auto mb-8"
          src={fabriqoLogo}
        />

        <h2 className="inline-block p-3 mb-4 text-4xl font-bold">
          The Connected Fashion
        </h2>

        <p className="mb-4">
          Fabriqo addresses the Fashion industry’s high barrier to digitization, which has for decades impeded the meaningful adoption of the sustainable innovations and solutions that contemporary consumers demand.
        </p>
        <p className="mb-4">
          By developing an open, cloud-based platform, we are creating the structure and tools needed for the industry and community to share, discuss and agree on best practices in terms of data formats and knowledge. Encouraging experienced industry leaders to contribute with their knowledge could reduce the start-up time for newcomers, paving the way for innovative solutions.
        </p>
        <p className="mb-4">
          On-demand access to such quality data will dramatically influence workflows and decision making, positively impacting speed-to-market, profitability, and sustainability, thus better-achieving user goals throughout.
        </p>
      </section>
    </Layout>
  );
}

export default IndexPage;
